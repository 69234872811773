import { AriaTooltipAndTrigger, Placement } from '@decernointernal/fb-interna-komponenter';

export interface ButtonIconProps {
  imageSrc: string;
  imageAlt?: string;
  disabled?: boolean;
  tooltip?: { text: string; position: Placement; className?: string };
  className?: string;
  onClick?: () => void;
}

export const ButtonIcon: React.FC<ButtonIconProps> = ({
  imageSrc,
  imageAlt,
  disabled,
  tooltip,
  className,
  onClick,
}) => {
  const button = (
    <div className={`relative ${className ?? ''}`}>
      <button
        disabled={disabled}
        className="peer border p-1 rounded-sm
          border-fb-color-secondary-border bg-fb-color-secondary
          hover:border-fb-color-secondary-border-compliment hover:bg-fb-color-secondary-compliment
          disabled:bg-fb-color-secondary/40
        "
      >
        <img className="cursor-pointer" src={imageSrc} alt={imageAlt} onClick={onClick}></img>
      </button>
    </div>
  );

  return tooltip ? <AriaTooltipAndTrigger tooltips={[tooltip]}>{button}</AriaTooltipAndTrigger> : button;
};
