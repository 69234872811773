import { AriaTooltipAndTrigger } from '@decernointernal/fb-interna-komponenter';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { FbScrollingCategoryHeader } from 'shared-components/category/FbScrollingCategoryHeader';

export interface FbGridColumnHeaderScrolling {
  type: 'scrollingHeader';
  className?: string;
  zIndex?: number;
}
export interface FbGridColumnHeaderValue {
  type: 'value';
  value: string;
  className: string;
  wrapperClassName: string;
  zIndex?: number;
  infoSymbolTooltipText?: string;
}
export interface FbGridColumnHeaderElement {
  type: 'element';
  value: JSX.Element;
  wrapperClassName: string;
  zIndex?: number;
}
export interface FbGridColumnHeaderNone {
  type: 'none';
}
export type FbGridColumnHeader =
  | FbGridColumnHeaderScrolling
  | FbGridColumnHeaderValue
  | FbGridColumnHeaderElement
  | FbGridColumnHeaderNone;

export const FbGridColumnHeaderWrapperStyles = {
  collapsibleGrid: ' bg-fb-color-bg-main font-sans font-semibold text-xs-increased text-right pt-4 pr-2',
  table:
    ' bg-fb-color-blue-superlight font-sans text-xs-increased text-fb-color-typography border-t border-r border-b border-fb-color-grey-table-border leading-4',
};
export const FbGridColumnHeaderStyles = {
  collapsibleGrid: ' rounded-t h-full py-1',
  table: ' p-2',
};

interface FbGridHeaderProps {
  title: string;
  subtitle: string;
  headers: FbGridColumnHeader[];
  zIndex: number;
  displayShadow: boolean;
}

export const FbGridHeader: React.FC<FbGridHeaderProps> = ({ title, subtitle, headers, zIndex, displayShadow }) => {
  return (
    <>
      {headers.map((h, index) => {
        if (h.type === 'scrollingHeader') {
          return (
            <FbScrollingCategoryHeader
              key={index}
              title={title}
              subtitle={subtitle}
              className={`bg-fb-color-bg-main pt-4 pb-1 px-4 ${h.className || ''}`}
              zIndex={h.zIndex !== undefined ? h.zIndex : zIndex}
            />
          );
        } else if (h.type === 'value') {
          return (
            <div
              key={index}
              className={`${h.wrapperClassName} sticky top-0`}
              style={{ zIndex: h.zIndex !== undefined ? h.zIndex : zIndex }}
            >
              {!h.infoSymbolTooltipText && <div className={h.className}>{h.value}</div>}
              {h.infoSymbolTooltipText && (
                <AriaTooltipAndTrigger
                  tooltips={[{ text: h.infoSymbolTooltipText, position: 'top', className: 'max-w-[400px]' }]}
                >
                  <div className={`group flex items-center ${h.className}`}>
                    <div>{h.value}</div>
                    <FontAwesomeIcon className="text-fb-color-primary pl-1" icon={faCircleInfo} />
                  </div>
                </AriaTooltipAndTrigger>
              )}
            </div>
          );
        } else if (h.type === 'element') {
          return (
            <div
              key={index}
              className={`${h.wrapperClassName} sticky top-0`}
              style={{ zIndex: h.zIndex !== undefined ? h.zIndex : zIndex }}
            >
              {h.value}
            </div>
          );
        } else {
          return null;
        }
      })}
      {displayShadow && (
        <div className="col-start-1 col-end-[-1] h-0 mx-4 shadow-in-grid z-30 sticky" style={{ top: 44 + 'px' }} />
      )}
    </>
  );
};
