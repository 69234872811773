import {
  addSpacesToNumber,
  AriaTooltipAndTrigger,
  FbSpinner,
  formatValue,
} from '@decernointernal/fb-interna-komponenter';
import { CommandFunctions, RemoteDataFunctions } from '@decernointernal/websd.shared';
import { faCircleInfo, faInfoCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NyckeltalDomain } from 'domain/nyckeltalDomain';
import {
  BudgetEnhetPair,
  BudgetListDTO,
  BudgetnyckeltalListDTO,
  BudgetnyckeltalPK,
  NyckeltalvaerdeUtfallUnderliggandeDTO,
  Typ,
  UnderliggandeEnhetVaerdeDTO,
} from 'generated-models/budgetera';
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ButtonIcon } from 'shared-components/buttonIcon/ButtonIcon';
import { FbGridColumnHeaderStyles, FbGridColumnHeaderWrapperStyles } from 'shared-components/grid/FbGridHeader';
import { FbTableGrid, FbTableGridRowDef } from 'shared-components/grid/table/FbTableGrid';
import { ThunkAction, useAppDispatch } from 'store/store';
import { getIsNyckeltalOmsaettningskrav, getIsNyckeltalSpecificDate } from 'utils/nyckeltalsUtils';
import distributeIcon from '../../../../icons/Fordela-forvantningar-ALT.svg';
import {
  selectUpdateBudgetNyckeltalFoervaentningCmd,
  updateBudgetNyckeltalFoervaentningAction,
} from '../../cmdUpdateBudgetNyckeltalFoervaentning';
import { selectUnderliggandeEnheterQry } from '../../qryUnderliggandeEnheter';
import {
  selectUnderliggandeEnheterVaerdeQry,
  underliggandeEnheterVaerdeQryAction,
} from '../../qryUnderliggandeEnheterVaerde';
import { selectNyckeltalvaerdeQry } from '../qryNyckeltalVaerde';
import { getUnderliggandeNamn } from '../underliggande';
import { FoervaentningModal } from './FoervaentningModal';
import { getUnderliggandeUtfallQryAction, selectUnderliggandeUtfallQry } from './qryUnderliggandeUtfall';

interface UnderliggandeEnheterProps {
  budget: BudgetListDTO;
  nyckeltal: BudgetnyckeltalListDTO;
  egetBudgeteratVaerde?: number | null;
}

export const UnderliggandeEnheter: React.FC<UnderliggandeEnheterProps> = ({
  budget,
  nyckeltal,
  egetBudgeteratVaerde,
}) => {
  const underliggandeEnheter = useSelector(selectUnderliggandeEnheterQry(budget.BudgetPK)); // Denna uppdateras i Budget.tsx
  const underliggandeEnheterVaerdeQry = useSelector(
    selectUnderliggandeEnheterVaerdeQry({ BudgetId: budget.BudgetPK.BudgetId!, NyckeltalId: nyckeltal.NyckeltalId })
  );
  const updateBudgetNyckeltalFoervaentningCmd = useSelector(selectUpdateBudgetNyckeltalFoervaentningCmd);
  const underliggandeUtfall = useSelector(
    selectUnderliggandeUtfallQry({ BudgetId: budget.BudgetPK.BudgetId!, NyckeltalId: nyckeltal.NyckeltalId })
  );

  const pk = {
    BudgetId: nyckeltal.BudgetId,
    BudgetnyckeltalLoepnummer: nyckeltal.BudgetnyckeltalLoepnummer,
  };
  const nyckeltalVaerde = useSelector(selectNyckeltalvaerdeQry(pk));

  const dispatch = useAppDispatch();

  const [showFoervaentningModal, setShowFoervaentningModal] = useState(false);

  const updateBudgetNyckeltalFoervaentning = (
    primaryKey: BudgetnyckeltalPK,
    value?: number
  ): ThunkAction | undefined => {
    if (
      !CommandFunctions.isExecuting(updateBudgetNyckeltalFoervaentningCmd) &&
      RemoteDataFunctions.hasData(underliggandeEnheter)
    ) {
      var BudgetIdList: number[] = [primaryKey.BudgetId!];
      return updateBudgetNyckeltalFoervaentningAction(
        {
          FoervaentningVaerde: value,
          PrimaryKey: primaryKey,
        },
        { BudgetId: budget.BudgetPK.BudgetId!, NyckeltalId: nyckeltal.NyckeltalId },
        BudgetIdList
      );
    }
    return undefined;
  };

  React.useEffect(() => {
    if (RemoteDataFunctions.isNotAsked(underliggandeUtfall) && RemoteDataFunctions.isSuccess(underliggandeEnheter)) {
      dispatch(
        getUnderliggandeUtfallQryAction(
          nyckeltal.NyckeltalId,
          budget.BudgetPK,
          budget.Kalenderaar,
          underliggandeEnheter.data.UnderliggandeEnheter.map(
            u => ({ EnhetVO: u.EnhetVO, BudgetId: u.BudgetPK.BudgetId } as BudgetEnhetPair)
          )
        )
      );
    }
  }, [budget.BudgetPK, budget.Kalenderaar, dispatch, nyckeltal.NyckeltalId, underliggandeEnheter, underliggandeUtfall]);

  React.useEffect(() => {
    if (
      RemoteDataFunctions.isNotAsked(underliggandeEnheterVaerdeQry) &&
      RemoteDataFunctions.hasData(underliggandeEnheter)
    ) {
      var BudgetIdList: number[] = [];
      underliggandeEnheter.data.UnderliggandeEnheter.forEach(enhet => BudgetIdList.push(enhet.BudgetPK.BudgetId!));
      dispatch(
        underliggandeEnheterVaerdeQryAction(
          { BudgetId: budget.BudgetPK.BudgetId!, NyckeltalId: nyckeltal.NyckeltalId },
          BudgetIdList
        )
      );
    }
  });

  const footerStyle = ' border-t-2 border-fb-color-primary bg-fb-color-white w-full h-full p-2 text-xs-increased';

  const getUnderliggandeEnheterVaerdeSum = (
    underliggandeEnhetVaerde: UnderliggandeEnhetVaerdeDTO[],
    subtractEgetBudgeteratVaerde: boolean = false,
    getBudgeteratVaerde: boolean = false
  ) => {
    if (!nyckeltal.SkaSummeras) return '-';

    const isThereAnyValues = getBudgeteratVaerde
      ? underliggandeEnhetVaerde.find(enhet => enhet.BudgeteratVaerde !== undefined)
      : underliggandeEnhetVaerde.find(enhet => enhet.FoervaentningVaerde !== undefined);
    if (isThereAnyValues === undefined) return '-';

    let sum = getBudgeteratVaerde
      ? underliggandeEnhetVaerde.reduce((sum, enhet) => sum + (enhet.BudgeteratVaerde ?? 0), 0)
      : underliggandeEnhetVaerde.reduce((sum, enhet) => sum + (enhet.FoervaentningVaerde ?? 0), 0);

    if (subtractEgetBudgeteratVaerde) {
      sum -= egetBudgeteratVaerde ?? 0;
      if (sum >= 0) return '+ ' + addSpacesToNumber(sum.toString());
    }

    let sumString = addSpacesToNumber(sum.toString());
    if (sumString.includes('-')) return sumString.replace('-', '- ');
    return sumString;
  };

  const getSumAndDiffStyling = (
    underliggandeEnhetVaerde: UnderliggandeEnhetVaerdeDTO[],
    getBudgeteratStyling: boolean = false
  ) => {
    const sum = getUnderliggandeEnheterVaerdeSum(underliggandeEnhetVaerde, true, getBudgeteratStyling);
    if (sum.includes('-') && sum.length > 1) return 'text-fb-color-danger';
    return 'text-fb-color-typography';
  };

  const monthAndYear = (date: Date): string => {
    var month = date
      .toLocaleString('sv-SE', {
        month: 'short',
      })
      .replace('.', '');

    return `${month} ${date.getFullYear()}`;
  };

  const getDateMonthsAgo = (date: Date, monthsAgo: number): Date => {
    var newDate = new Date();
    newDate.setMonth(date.getMonth() - monthsAgo);
    return newDate;
  };

  const getUnderliggandeUtfallFormatted = (
    underliggandeUtfallList: NyckeltalvaerdeUtfallUnderliggandeDTO[],
    budgetId: number | null | undefined
  ) => {
    const underliggande = underliggandeUtfallList.find(u => u.BudgetPK.BudgetId === budgetId);
    if (underliggande) {
      return {
        UtfallFoeregaaendeAar:
          underliggande.UtfallFoeregaaendeAar != null
            ? addSpacesToNumber(underliggande.UtfallFoeregaaendeAar.toString()).replaceAll('.', ',')
            : '-',
        UtfallSenasteTolvMaanaderna:
          underliggande.UtfallSenasteTolvMaanaderna != null
            ? addSpacesToNumber(underliggande.UtfallSenasteTolvMaanaderna.toString()).replaceAll('.', ',')
            : '-',
        UtfallTvaaAarSedan:
          underliggande.UtfallTvaaAarSedan != null
            ? addSpacesToNumber(underliggande.UtfallTvaaAarSedan.toString()).replaceAll('.', ',')
            : '-',
      };
    }

    return { UtfallFoeregaaendeAar: '-', UtfallSenasteTolvMaanaderna: '-', UtfallTvaaAarSedan: '-' };
  };

  const isNyckeltalSpecificDate = getIsNyckeltalSpecificDate(nyckeltal.NyckeltalId);
  const isOmsaettningskrav = getIsNyckeltalOmsaettningskrav(nyckeltal.NyckeltalId) && !!budget.EnhetVO.KontorsgruppId;
  const isAntalAffaerer =
    (nyckeltal.NyckeltalId === NyckeltalDomain.BR.AntalAffaerer ||
      nyckeltal.NyckeltalId === NyckeltalDomain.Smaahus.AntalAffaerer) &&
    !!budget.EnhetVO.KontorsgruppId;

  return (
    <>
      {(!RemoteDataFunctions.hasData(underliggandeEnheter) ||
        !RemoteDataFunctions.hasData(underliggandeUtfall) ||
        !RemoteDataFunctions.hasData(underliggandeEnheterVaerdeQry)) && (
        <div className="flex flex-col justify-center">
          <FbSpinner size={'large'} />
        </div>
      )}
      {RemoteDataFunctions.hasData(underliggandeEnheter) &&
        RemoteDataFunctions.hasData(underliggandeEnheterVaerdeQry) &&
        RemoteDataFunctions.hasData(underliggandeUtfall) && (
          <FbTableGrid
            containerClassName="custom-scroll-underliggande"
            className="w-full"
            columns={[
              {
                columnTemplate: 'minmax(256px, 25%)',
                header: [
                  {
                    type: 'value',
                    className: '',
                    value: '',
                    wrapperClassName: ' bg-fb-color-white col-start-1 col-end-4',
                  },
                  {
                    type: 'value',
                    className: ' text-center text-xs-increased',
                    value: 'Utfall',
                    wrapperClassName: ' bg-fb-color-white col-start-4 col-end-7 pt-1 pb-2',
                  },
                  {
                    type: 'value',
                    value: getUnderliggandeNamn(budget, true),
                    className: ' capitalize text-left' + FbGridColumnHeaderStyles.table,
                    wrapperClassName: ' border-l top-8' + FbGridColumnHeaderWrapperStyles.table,
                  },
                ],
                footer: {
                  type: 'element',
                  element: (
                    <div className={`${footerStyle} font-semibold flex flex-col justify-between`}>
                      <span>Summa</span>
                      <span>
                        {nyckeltal.Typ === Typ.Angivet
                          ? 'Diff mot eget budgeterat värde'
                          : 'Diff mot eget beräknat värde'}
                      </span>
                    </div>
                  ),
                },
              },
              {
                columnTemplate: 'minmax(max-content, 15%)',
                header: {
                  type: 'value',
                  value: nyckeltal.Typ === Typ.Angivet ? 'Budgeterat värde' : 'Beräknat värde',
                  className: 'text-right' + FbGridColumnHeaderStyles.table,
                  wrapperClassName: ' top-8' + FbGridColumnHeaderWrapperStyles.table,
                },
                footer: {
                  type: 'element',
                  element: (
                    <div className={`${footerStyle} text-right font-semibold flex flex-col justify-between`}>
                      <span>
                        {getUnderliggandeEnheterVaerdeSum(
                          underliggandeEnheterVaerdeQry.data.UnderliggandeEnhetVaerde,
                          false,
                          true
                        )}
                      </span>
                      <span
                        className={`mt-1 ${getSumAndDiffStyling(
                          underliggandeEnheterVaerdeQry.data.UnderliggandeEnhetVaerde,
                          true
                        )}`}
                      >
                        {getUnderliggandeEnheterVaerdeSum(
                          underliggandeEnheterVaerdeQry.data.UnderliggandeEnhetVaerde,
                          true,
                          true
                        )}
                      </span>
                    </div>
                  ),
                },
              },
              {
                columnTemplate: 'minmax(max-content, 15%)',
                header: {
                  type: 'element',
                  value: isOmsaettningskrav ? (
                    <AriaTooltipAndTrigger
                      tooltips={[
                        {
                          text: 'Siffrorna kommer från franchiseavtalen',
                          position: 'top',
                          className: 'max-w-[400px]',
                        },
                      ]}
                    >
                      <div
                        className={'flex justify-between h-full group items-center' + FbGridColumnHeaderStyles.table}
                      >
                        Förväntning
                        <FontAwesomeIcon className="text-fb-color-primary pl-1" icon={faCircleInfo} />
                      </div>
                    </AriaTooltipAndTrigger>
                  ) : (
                    <div className={'flex justify-between h-full group items-center' + FbGridColumnHeaderStyles.table}>
                      <ButtonIcon
                        className="-m-2 -mt-1 pl-2"
                        imageSrc={distributeIcon}
                        imageAlt={'Fördela förväntningar'}
                        onClick={() => setShowFoervaentningModal(true)}
                        tooltip={{
                          text: 'Fördela ut förväntningar baserat på historiskt utfall',
                          position: 'top',
                          className: 'max-w-[400px]',
                        }}
                      />
                      Förväntning
                    </div>
                  ),
                  wrapperClassName: ' mr-2 top-8' + FbGridColumnHeaderWrapperStyles.table,
                },
                footer: {
                  type: 'element',
                  className: 'pr-2',
                  element: (
                    <div className={`${footerStyle} text-right font-semibold flex flex-col justify-between`}>
                      <span>
                        {getUnderliggandeEnheterVaerdeSum(underliggandeEnheterVaerdeQry.data.UnderliggandeEnhetVaerde)}
                      </span>
                      <span
                        className={`mt-1 ${getSumAndDiffStyling(
                          underliggandeEnheterVaerdeQry.data.UnderliggandeEnhetVaerde
                        )}`}
                      >
                        {getUnderliggandeEnheterVaerdeSum(
                          underliggandeEnheterVaerdeQry.data.UnderliggandeEnhetVaerde,
                          true
                        )}
                      </span>
                    </div>
                  ),
                },
              },

              {
                columnTemplate: 'minmax(max-content, 15%)',
                header: {
                  type: 'value',
                  value: isNyckeltalSpecificDate ? 'Aktuellt värde' : 'Senaste 12 mån',
                  infoSymbolTooltipText: isNyckeltalSpecificDate
                    ? undefined
                    : `För perioden ${monthAndYear(getDateMonthsAgo(new Date(), 12))} till ${monthAndYear(
                        getDateMonthsAgo(new Date(), 1)
                      )}`,
                  className: 'text-right justify-end' + FbGridColumnHeaderStyles.table,
                  wrapperClassName: ' border-l top-8' + FbGridColumnHeaderWrapperStyles.table,
                },
                footer: {
                  type: 'element',
                  element: (
                    <div className={`${footerStyle} text-right font-semibold italic text-fb-grey-dark-2`}>
                      {addSpacesToNumber(
                        underliggandeUtfall.data.NyckeltalvaerdeUtfallUnderliggandeList.reduce(
                          (sum, currentValue) => sum + (currentValue.UtfallSenasteTolvMaanaderna ?? 0),
                          0
                        )
                          .toFixed(1)
                          .replace('.0', '')
                          .toString()
                      ).replaceAll('.', ',')}
                    </div>
                  ),
                },
              },
              {
                columnTemplate: 'minmax(max-content, 15%)',
                header: {
                  type: 'value',
                  value: isNyckeltalSpecificDate ? `${budget.Kalenderaar - 2}-12-31` : `${budget.Kalenderaar - 2}`,
                  className: 'text-right' + FbGridColumnHeaderStyles.table,
                  wrapperClassName: ' top-8' + FbGridColumnHeaderWrapperStyles.table,
                },
                footer: {
                  type: 'element',
                  element: (
                    <div className={`${footerStyle} text-right font-semibold italic text-fb-grey-dark-2`}>
                      {addSpacesToNumber(
                        underliggandeUtfall.data.NyckeltalvaerdeUtfallUnderliggandeList.reduce(
                          (sum, currentValue) => sum + (currentValue.UtfallFoeregaaendeAar ?? 0),
                          0
                        )
                          .toFixed(1)
                          .replace('.0', '')
                          .toString()
                      ).replaceAll('.', ',')}
                    </div>
                  ),
                },
              },
              {
                columnTemplate: 'minmax(max-content, 15%)',
                header: {
                  type: 'value',
                  value: isNyckeltalSpecificDate ? `${budget.Kalenderaar - 3}-12-31` : `${budget.Kalenderaar - 3}`,
                  className: 'text-right' + FbGridColumnHeaderStyles.table,
                  wrapperClassName: ' top-8' + FbGridColumnHeaderWrapperStyles.table,
                },
                footer: {
                  type: 'element',
                  element: (
                    <div className={`${footerStyle} text-right font-semibold italic text-fb-grey-dark-2`}>
                      {addSpacesToNumber(
                        underliggandeUtfall.data.NyckeltalvaerdeUtfallUnderliggandeList.reduce(
                          (sum, currentValue) => sum + (currentValue.UtfallTvaaAarSedan ?? 0),
                          0
                        )
                          .toFixed(1)
                          .replace('.0', '')
                          .toString()
                      ).replaceAll('.', ',')}
                    </div>
                  ),
                },
              },
            ]}
            rows={underliggandeEnheter.data.UnderliggandeEnheter.map(underliggande => {
              const underliggandeEnhetVaerde = underliggandeEnheterVaerdeQry.data.UnderliggandeEnhetVaerde.find(
                v => v.BudgetnyckeltalPK.BudgetId === underliggande.BudgetPK.BudgetId
              );
              const omsaettningskravDiff =
                underliggandeEnhetVaerde?.Omsaettningskrav === undefined ||
                underliggandeEnhetVaerde?.SkattadMarknadandel === undefined
                  ? undefined
                  : Math.round(
                      (underliggandeEnhetVaerde?.SkattadMarknadandel! - underliggandeEnhetVaerde?.Omsaettningskrav!) *
                        10
                    ) / 10;
              const value: FbTableGridRowDef = {
                values: [
                  {
                    value: `${underliggande.Namn}${underliggande.EnhetVO.PlaneradBudgetId ? ' (Planerad)' : ''}${
                      underliggande.AerNyEnhet ? ' (Ny)' : ''
                    }`,
                    className: ' border-l border-fb-color-grey-table-border',
                  },
                  {
                    value: underliggandeEnhetVaerde?.BudgeteratVaerde
                      ? formatValue(underliggandeEnhetVaerde.BudgeteratVaerde.toString(), 'number', false).replaceAll(
                          '.',
                          ','
                        )
                      : '-',
                    className: ' text-right',
                  },
                  {
                    value: (isOmsaettningskrav
                      ? underliggandeEnhetVaerde?.Omsaettningskrav
                      : underliggandeEnhetVaerde?.FoervaentningVaerde
                    )
                      ?.toString()
                      .replaceAll('.', ','),
                    className: 'mr-2',
                    tooltipIconClassName:
                      omsaettningskravDiff === undefined || omsaettningskravDiff < 0
                        ? ' text-fb-color-warning'
                        : ' text-fb-color-primary',
                    tooltipIcon:
                      isAntalAffaerer && underliggandeEnhetVaerde?.FoervaentningVaerde !== undefined
                        ? omsaettningskravDiff === undefined || omsaettningskravDiff < 0
                          ? faWarning
                          : faInfoCircle
                        : undefined,
                    tooltipText:
                      isAntalAffaerer && underliggandeEnhetVaerde?.FoervaentningVaerde !== undefined
                        ? `Skattad marknadsandel utifrån förväntning på antal affärer: ${
                            underliggandeEnhetVaerde?.SkattadMarknadandel?.toFixed(1).replaceAll('.', ',') ?? '-'
                          } %
Omsättningskrav: ${underliggandeEnhetVaerde?.Omsaettningskrav?.toFixed(1).replaceAll('.', ',') ?? '-'} %
Differens: ${omsaettningskravDiff?.toFixed(1).replaceAll('.', ',') ?? '-'} %`
                        : undefined,
                    tooltipClassName: 'max-w-[410px]',
                    inputCellProps: isOmsaettningskrav
                      ? undefined
                      : {
                          type: 'number',
                          id: underliggandeEnhetVaerde?.BudgetnyckeltalPK.BudgetId?.toString()!,
                          onSave: (value: string | undefined): ThunkAction | undefined => {
                            if (value === undefined) return;
                            value = value?.replaceAll(' ', '');
                            if (
                              value === underliggandeEnhetVaerde?.FoervaentningVaerde?.toString() ||
                              (!underliggandeEnhetVaerde?.FoervaentningVaerde && value === '')
                            ) {
                              return undefined;
                            }
                            const numVal = Number(value);
                            return updateBudgetNyckeltalFoervaentning(
                              underliggandeEnhetVaerde?.BudgetnyckeltalPK!,
                              value === '' ? undefined : numVal
                            );
                          },
                        },
                  },
                  {
                    value:
                      underliggandeUtfall.data.NyckeltalvaerdeUtfallUnderliggandeList.length === 0
                        ? '-'
                        : getUnderliggandeUtfallFormatted(
                            underliggandeUtfall.data.NyckeltalvaerdeUtfallUnderliggandeList,
                            underliggande.BudgetPK.BudgetId
                          )?.UtfallSenasteTolvMaanaderna,
                    className: ' text-right text-fb-grey-dark-2 italic border-l border-fb-color-grey-table-border',
                  },
                  {
                    value:
                      underliggandeUtfall.data.NyckeltalvaerdeUtfallUnderliggandeList.length === 0
                        ? '-'
                        : getUnderliggandeUtfallFormatted(
                            underliggandeUtfall.data.NyckeltalvaerdeUtfallUnderliggandeList,
                            underliggande.BudgetPK.BudgetId
                          )?.UtfallFoeregaaendeAar,
                    className: ' text-right text-fb-grey-dark-2 italic',
                  },
                  {
                    value:
                      underliggandeUtfall.data.NyckeltalvaerdeUtfallUnderliggandeList.length === 0
                        ? '-'
                        : getUnderliggandeUtfallFormatted(
                            underliggandeUtfall.data.NyckeltalvaerdeUtfallUnderliggandeList,
                            underliggande.BudgetPK.BudgetId
                          )?.UtfallTvaaAarSedan,
                    className: ' text-right text-fb-grey-dark-2 italic',
                  },
                ],
              };
              return value;
            }).sort((rowA, rowB) => {
              var namnA = rowA.values[0].value?.toString().toUpperCase();
              var namnB = rowB.values[0].value?.toString().toUpperCase();

              if (namnA != null && namnB != null) {
                if (namnA < namnB) return -1;
                if (namnB > namnA) return 1;
                return 0;
              }
              if (namnA != null) return 1;
              if (namnB != null) return -1;
              return 0;
            })}
          />
        )}
      {showFoervaentningModal &&
        RemoteDataFunctions.hasData(underliggandeEnheter) &&
        RemoteDataFunctions.hasData(underliggandeEnheterVaerdeQry) &&
        RemoteDataFunctions.hasData(underliggandeUtfall) &&
        RemoteDataFunctions.hasData(nyckeltalVaerde) && (
          <FoervaentningModal
            budget={budget}
            nyckeltal={nyckeltal}
            onConfirm={() => setShowFoervaentningModal(false)}
            onCancel={() => setShowFoervaentningModal(false)}
            underliggandeEnheter={underliggandeEnheter.data}
            underliggandeEnheterVaerdeQry={underliggandeEnheterVaerdeQry.data}
            underliggandeUtfall={underliggandeUtfall.data}
            nyckeltalVaerde={nyckeltalVaerde.data}
          />
        )}
    </>
  );
};
