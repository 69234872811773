import { NyckeltalDomain } from 'domain/nyckeltalDomain';
import { getSummaUnderliggandeStatusDomain } from 'domain/SummaUnderliggandeStatusDomain';
import {
  BudgetListDTO,
  BudgetnyckeltalListDTO,
  SummaUnderliggande,
  SummaUnderliggandeStatusVO,
} from 'generated-models/budgetera/models';
import * as React from 'react';
import { BudgetCell } from 'shared-components/cell/BudgetCell';
import { isKontor } from 'utils/enhet';
import { getEnhetText } from 'utils/nyckeltalsUtils';
import { getUnderliggandeNamnBudgetVyn } from './underliggande';

interface NyckeltalHeaderProps {
  nyckeltal: BudgetnyckeltalListDTO;
  budget: BudgetListDTO;
  isCollapsed: boolean;
  foervaentning?: number | null;
  budgeteratValue?: number | null;
  summaMaanader: number | undefined;
  summaUnderliggande?: SummaUnderliggande;
}

export const NyckeltalHeader: React.FC<NyckeltalHeaderProps> = ({
  nyckeltal,
  budget,
  isCollapsed,
  foervaentning,
  budgeteratValue,
  summaMaanader,
  summaUnderliggande,
}) => {
  const [varningSummaUnderliggande, setVarningSummaUnderliggande] = React.useState(false);
  const [varningSummaMaanader, setVarningSummaMaanader] = React.useState(false);
  const [varningFoervaentning, setVarningFoervaentning] = React.useState(false);

  React.useEffect(() => {
    if (
      summaUnderliggande &&
      budgeteratValue &&
      getSummaUnderliggandeStatusDomain(summaUnderliggande.Status) === 'all underliggande enheter has vaerden' &&
      (summaUnderliggande?.Summa ?? 0) - (budgeteratValue ?? 0) < 0
    ) {
      setVarningSummaUnderliggande(true);
    } else {
      setVarningSummaUnderliggande(false);
    }
  }, [budgeteratValue, summaUnderliggande]);

  React.useEffect(() => {
    if (!summaMaanader) {
      return;
    }
    if (
      budgeteratValue !== null &&
      budgeteratValue !== undefined &&
      Math.round(budgeteratValue) !== Math.round(summaMaanader)
    ) {
      setVarningSummaMaanader(true);
    } else {
      setVarningSummaMaanader(false);
    }
  }, [budgeteratValue, summaMaanader]);

  React.useEffect(() => {
    if (!foervaentning) {
      return;
    }
    if ((budgeteratValue ?? 0) - (foervaentning ?? 0) < 0) {
      setVarningFoervaentning(true);
    } else {
      setVarningFoervaentning(false);
    }
  }, [budgeteratValue, foervaentning]);

  const summaUnderliggandeBudgetCell = (summaUnderliggande: SummaUnderliggande | undefined, isCollapsed: boolean) => {
    return (
      <BudgetCell
        value={summaUnderliggande ? getSummaUnderliggandeText(summaUnderliggande) : null}
        className={`mr-1 ${summaUnderliggande ? getSummaUnderliggandeTextClassName(summaUnderliggande?.Status) : ''}`}
        isCollapsed={isCollapsed}
        tooltips={[
          {
            text: 'Det finns fortfarande budgetar som ännu inte klarmarkerats eller som saknar budgeterat värde.',
            className: 'max-w-[250px]',
            isShowing:
              !!summaUnderliggande?.Status &&
              getSummaUnderliggandeStatusDomain(summaUnderliggande?.Status) ===
                'some but not all underliggande enheter has vaerden',
            position: 'top',
          },
        ]}
      />
    );
  };

  const getSummaUnderliggandeText = (summaUnderliggande: SummaUnderliggande): string | null => {
    switch (getSummaUnderliggandeStatusDomain(summaUnderliggande.Status)) {
      case 'should not summeras':
        return null;
      case 'no underliggande enheter has vaerden':
        return '-';
      case 'some but not all underliggande enheter has vaerden':
        return `(${summaUnderliggande.Summa})`;
      case 'all underliggande enheter has vaerden':
        return summaUnderliggande.Summa?.toString() ?? null;
    }
    return null;
  };

  const getSummaUnderliggandeTextClassName = (status: SummaUnderliggandeStatusVO) => {
    switch (getSummaUnderliggandeStatusDomain(status)) {
      case 'some but not all underliggande enheter has vaerden':
        return 'text-fb-grey-dark-2';
      default:
        return '';
    }
  };

  const FormatDifferens = (differens: number): string => {
    if (differens < 0) {
      return `- ${-differens}`;
    } else {
      return differens.toString();
    }
  };

  const GetSummaDifferensValue = (
    summaUnderliggande: SummaUnderliggande | undefined,
    BudgeteratValue: number | undefined | null
  ): string => {
    if (summaUnderliggande) {
      const differens = (summaUnderliggande.Summa ?? 0) - (BudgeteratValue ?? 0);
      switch (getSummaUnderliggandeStatusDomain(summaUnderliggande.Status)) {
        case 'should not summeras':
          return '';
        case 'no underliggande enheter has vaerden':
          return '-';
        case 'some but not all underliggande enheter has vaerden':
          return `(${FormatDifferens(differens)})`;
        case 'all underliggande enheter has vaerden':
          return `${FormatDifferens(differens)}`;
      }
    }
    return '';
  };

  const GetSummaDifferensClassName = (
    summaUnderliggande: SummaUnderliggande | undefined,
    BudgeteratValue: number | undefined | null
  ): string => {
    if (summaUnderliggande) {
      switch (getSummaUnderliggandeStatusDomain(summaUnderliggande.Status)) {
        case 'some but not all underliggande enheter has vaerden':
          return 'text-fb-grey-dark-2';
        case 'all underliggande enheter has vaerden':
          if ((summaUnderliggande.Summa ?? 0) - (BudgeteratValue ?? 0) < 0) {
            return 'text-fb-color-danger';
          }
      }
    }
    return '';
  };

  return (
    <>
      <div
        className={`py-2 pl-2 ml-4 transition-all duration-300  ${
          isCollapsed ? 'bg-fb-color-white' : 'bg-fb-color-primary'
        }`}
      >
        <h3 className={`mr-1 ${isCollapsed ? 'text-fb-color-typography' : 'text-fb-color-white absolute'}`}>
          {(!isCollapsed && nyckeltal.Subkategori ? nyckeltal.Subkategori + ' - ' : '') +
            nyckeltal.Namn +
            ` (${getEnhetText(nyckeltal.Enhet, budget.AerUtland)})`}
        </h3>
      </div>
      {nyckeltal.BudgetnyckeltalLoepnummer && (
        <>
          <BudgetCell
            value={budgeteratValue?.toString() || '-'}
            className="mr-4"
            isCollapsed={isCollapsed}
            nyckeltalTyp={nyckeltal.Typ}
            varningFinns={varningSummaUnderliggande || varningSummaMaanader || varningFoervaentning}
            tooltips={[
              {
                isShowing: varningSummaUnderliggande,
                className: 'max-w-[400px]',
                position: 'right',
                text: `Ditt budgeterade värde överstiger summan av ${getUnderliggandeNamnBudgetVyn(
                  budget
                ).toLocaleLowerCase()}s budgetar`,
              },
              {
                isShowing: varningFoervaentning,
                className: 'max-w-[400px]',
                position: 'bottom',
                text: 'Ditt budgeterade värde är lägre än förväntan',
              },
              {
                isShowing: varningSummaMaanader,
                className: 'max-w-[400px]',
                position: 'top',
                text: 'Summa budgeterade månadsvärden stämmer inte överens med budgeterat årsvärde',
              },
            ]}
          />
          <BudgetCell
            value={foervaentning?.toString() || ''}
            className="mr-1"
            isCollapsed={isCollapsed}
            tooltips={[
              {
                text: 'Siffran kommer från franchiseavtalet',
                className: 'max-w-[400px]',
                isShowing:
                  isKontor(budget.EnhetVO) &&
                  foervaentning !== undefined &&
                  foervaentning !== null &&
                  (nyckeltal.NyckeltalId === NyckeltalDomain.BR.Marknadsandel ||
                    nyckeltal.NyckeltalId === NyckeltalDomain.Smaahus.Marknadsandel),
                position: 'top',
              },
            ]}
            infoFinns={
              isKontor(budget.EnhetVO) &&
              foervaentning !== undefined &&
              foervaentning !== null &&
              (nyckeltal.NyckeltalId === NyckeltalDomain.BR.Marknadsandel ||
                nyckeltal.NyckeltalId === NyckeltalDomain.Smaahus.Marknadsandel)
            }
          />
          <BudgetCell
            value={
              foervaentning === null || foervaentning === undefined
                ? ''
                : ((budgeteratValue ?? 0) - foervaentning).toString()
            }
            className={`mr-4 ${
              budgeteratValue && foervaentning && budgeteratValue - foervaentning < 0 ? 'text-fb-color-danger' : ''
            }`}
            isCollapsed={isCollapsed}
          />
          {summaUnderliggandeBudgetCell(summaUnderliggande, isCollapsed)}
          <BudgetCell
            value={GetSummaDifferensValue(summaUnderliggande, budgeteratValue)}
            className={`${GetSummaDifferensClassName(summaUnderliggande, budgeteratValue)} mr-4`}
            isCollapsed={isCollapsed}
            tooltips={[
              {
                text: 'Det finns fortfarande budgetar som ännu inte klarmarkerats eller som saknar budgeterat värde.',
                className: 'max-w-[250px]',
                isShowing:
                  !!summaUnderliggande?.Status &&
                  getSummaUnderliggandeStatusDomain(summaUnderliggande?.Status) ===
                    'some but not all underliggande enheter has vaerden',
                position: 'top',
              },
            ]}
          />
        </>
      )}
      {!nyckeltal.BudgetnyckeltalLoepnummer && (
        // Detta nyckeltal är ett nyckeltal som inte finns på denna budget,
        // men som finns på underliggande budgetar.
        <>
          <BudgetCell value={null} className="mr-4" isCollapsed={isCollapsed} />
          <BudgetCell value={null} className="mr-1" isCollapsed={isCollapsed} />
          <BudgetCell value={null} className="mr-4" isCollapsed={isCollapsed} />
          {summaUnderliggandeBudgetCell(summaUnderliggande, isCollapsed)}
          <BudgetCell
            value={GetSummaDifferensValue(summaUnderliggande, budgeteratValue)}
            className={`${GetSummaDifferensClassName(summaUnderliggande, budgeteratValue)} mr-4`}
            isCollapsed={isCollapsed}
          />
        </>
      )}
    </>
  );
};
