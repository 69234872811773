import { addSpacesToNumber, AriaTooltipAndTrigger, Placement } from '@decernointernal/fb-interna-komponenter';
import { faInfoCircle, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typ } from 'generated-models/budgetera';
import { useMemo } from 'react';

export interface BudgetCellToolTip {
  text: string;
  className?: string;
  isShowing: boolean;
  position: Placement;
}

interface BudgetCellProps {
  value: string | null;
  className?: string;
  isCollapsed: boolean;
  nyckeltalTyp?: Typ;
  varningFinns?: boolean;
  infoFinns?: boolean;
  tooltips?: BudgetCellToolTip[];
}

export const BudgetCell: React.FC<BudgetCellProps> = ({
  value,
  className,
  isCollapsed,
  nyckeltalTyp,
  varningFinns,
  infoFinns,
  tooltips,
}) => {
  const getCellColor = () => {
    if (!isCollapsed) {
      return ' bg-fb-color-primary';
    } else if (nyckeltalTyp === Typ.Beraeknat || !nyckeltalTyp) {
      return ' bg-fb-color-white';
    } else {
      if (value && value !== '-') {
        return ' bg-fb-color-blue-superlight';
      }
      return ' bg-fb-color-orange/20';
    }
  };

  const tooltipsToShow = useMemo(() => tooltips?.filter(t => t.isShowing), [tooltips]);

  const iconBox = (
    <div>
      {varningFinns && <FontAwesomeIcon icon={faTriangleExclamation} className="text-fb-color-danger pr-1" />}
      {infoFinns && <FontAwesomeIcon icon={faInfoCircle} className="text-fb-color-primary pr-1" />}
    </div>
  );

  const valueBox = (
    <span className={varningFinns ? 'text-fb-color-danger' : ''}>
      {value && addSpacesToNumber(value.replaceAll('.', ','))}
    </span>
  );

  const renderWithTooltip = (tips: BudgetCellToolTip[]) => {
    return infoFinns || varningFinns ? (
      <AriaTooltipAndTrigger tooltips={tips} triggerClassName="w-full">
        <div className="flex justify-between w-full">
          {iconBox}
          {valueBox}
        </div>
      </AriaTooltipAndTrigger>
    ) : (
      <div className="flex justify-between w-full">
        {iconBox}
        <AriaTooltipAndTrigger tooltips={tips}>{valueBox}</AriaTooltipAndTrigger>
      </div>
    );
  };

  return (
    <div
      className={
        'text-fb-color-typography flex w-full transition-all duration-300 ' +
        (isCollapsed ? 'bg-fb-color-white' : 'bg-fb-color-primary')
      }
    >
      <div className={`${className} transition-all duration-300 h-full w-full ${getCellColor()}`}>
        <div
          className={`group relative flex justify-between transition-all duration-300 font-sans text-xs-increased w-full h-full items-center p-2 ${
            isCollapsed ? 'opacity-100' : 'opacity-0 transition-none'
          }`}
        >
          {isCollapsed && !!tooltipsToShow?.length ? (
            renderWithTooltip(tooltipsToShow)
          ) : (
            <div className="flex justify-between w-full">
              {iconBox}
              {valueBox}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
