import {
  addSpacesToNumber,
  AriaCombobox,
  AriaTooltipAndTrigger,
  FbFormInput,
  FbModal,
} from '@decernointernal/fb-interna-komponenter';
import { CommandFunctions } from '@decernointernal/websd.shared';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  BudgetListDTO,
  BudgetnyckeltalListDTO,
  NyckeltalVaerdeDTO,
  NyckeltalvaerdeUtfallUnderliggandeWrapperDTO,
  UnderliggandeEnheterDTO,
  UnderliggandeEnheterVaerdeListDTO,
} from 'generated-models/budgetera';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FbGridColumnHeaderStyles, FbGridColumnHeaderWrapperStyles } from 'shared-components/grid/FbGridHeader';
import { FbTableGrid, FbTableGridRowDef } from 'shared-components/grid/table/FbTableGrid';
import { useAppDispatch } from 'store/store';
import { getUnderliggandeNamn } from '../underliggande';
import { beraeknadFoervaentningFoerdelning } from './beraeknadFoervaentningFoerdelning';
import {
  removeBudgetNyckeltalFoervaentningarCmdAction,
  selectUpdateBudgetNyckeltalFoervaentningarCmd,
  updateBudgetNyckeltalFoervaentningarAction,
} from './cmdUpdateBudgetNyckeltalFoervaentningar';

interface FoervaentningModalProps {
  budget: BudgetListDTO;
  nyckeltal: BudgetnyckeltalListDTO;
  underliggandeEnheter: UnderliggandeEnheterDTO;
  underliggandeEnheterVaerdeQry: UnderliggandeEnheterVaerdeListDTO;
  underliggandeUtfall: NyckeltalvaerdeUtfallUnderliggandeWrapperDTO;
  nyckeltalVaerde: NyckeltalVaerdeDTO;
  onConfirm: () => void;
  onCancel: () => void;
}

export const FoervaentningModal: React.FC<FoervaentningModalProps> = ({
  budget,
  nyckeltal,
  underliggandeEnheter,
  underliggandeEnheterVaerdeQry,
  underliggandeUtfall,
  nyckeltalVaerde,
  onConfirm,
  onCancel,
}) => {
  const updateBudgetNyckeltalFoervaentningarCmd = useSelector(selectUpdateBudgetNyckeltalFoervaentningarCmd);
  const dispatch = useAppDispatch();

  const [totalFoerdelaFoervaentningInput, setTotalFoerdelaFoervaentningInput] = useState('');
  const [selectTargetFoerdelning, setSelectTargetFoerdelning] = React.useState<number | undefined>(1);
  const [underliggandeUtfallFoerdelning, setUnderliggandeUtfallFoerdelning] = useState<
    { budgetId: number; foerdelning: number | null | undefined }[]
  >([]);
  const [underliggandeUtfallSumma, setUnderliggandeUtfallSumma] = useState(0);

  const updateBudgetNyckeltalFoervaentningar = (underliggandeEnhetsVaerde: UnderliggandeEnheterVaerdeListDTO) => {
    if (!CommandFunctions.isExecuting(updateBudgetNyckeltalFoervaentningarCmd)) {
      var BudgetIdList: number[] = [];
      underliggandeEnheter.UnderliggandeEnheter.forEach(enhet => BudgetIdList.push(enhet.BudgetPK.BudgetId!));
      dispatch(
        updateBudgetNyckeltalFoervaentningarAction(
          {
            BudgetNyckeltalFoervaentningPairList: underliggandeEnhetsVaerde.UnderliggandeEnhetVaerde.map(
              underliggande => ({
                BudgetNyckeltalPK: underliggande.BudgetnyckeltalPK,
                FoervaentningVaerde:
                  underliggandeUtfallFoerdelning.find(
                    foerdelning => foerdelning.budgetId === underliggande.BudgetnyckeltalPK.BudgetId
                  )?.foerdelning ?? null,
              })
            ),
          },
          { BudgetId: budget.BudgetPK.BudgetId!, NyckeltalId: nyckeltal.NyckeltalId },
          BudgetIdList
        )
      );
    }
  };

  React.useEffect(() => {
    if (selectTargetFoerdelning === undefined) return;

    if (selectTargetFoerdelning === 1) {
      // Utfall senaste 12 månaderna
      const [foervaentningar, summa] = beraeknadFoervaentningFoerdelning(
        underliggandeUtfall.NyckeltalvaerdeUtfallUnderliggandeList.map(u => ({
          budgetId: u.BudgetPK.BudgetId,
          utfall: u.UtfallSenasteTolvMaanaderna,
        })),
        +totalFoerdelaFoervaentningInput
      );
      setUnderliggandeUtfallFoerdelning(foervaentningar);
      setUnderliggandeUtfallSumma(summa);
    } else if (selectTargetFoerdelning === 2) {
      // Utfall föregående år
      const [foervaentningar, summa] = beraeknadFoervaentningFoerdelning(
        underliggandeUtfall.NyckeltalvaerdeUtfallUnderliggandeList.map(u => ({
          budgetId: u.BudgetPK.BudgetId,
          utfall: u.UtfallFoeregaaendeAar,
        })),
        +totalFoerdelaFoervaentningInput
      );
      setUnderliggandeUtfallFoerdelning(foervaentningar);
      setUnderliggandeUtfallSumma(summa);
    }
  }, [
    selectTargetFoerdelning,
    totalFoerdelaFoervaentningInput,
    underliggandeUtfall.NyckeltalvaerdeUtfallUnderliggandeList,
  ]);

  React.useEffect(() => {
    if (CommandFunctions.isSuccess(updateBudgetNyckeltalFoervaentningarCmd)) {
      dispatch(removeBudgetNyckeltalFoervaentningarCmdAction());
      onConfirm();
    }
  }, [dispatch, onConfirm, updateBudgetNyckeltalFoervaentningarCmd]);

  return (
    <>
      <FbModal
        titel="Fördela ut förväntningar baserat på historiskt utfall"
        buttonConfirmText="Använd"
        buttonCancelText="Avbryt"
        confirmButtonState={
          CommandFunctions.isExecuting(updateBudgetNyckeltalFoervaentningarCmd) ? 'waiting' : 'default'
        }
        onCancel={() => onCancel()}
        onConfirm={() => {
          updateBudgetNyckeltalFoervaentningar(underliggandeEnheterVaerdeQry);
        }}
      >
        <div className="h-full flex flex-col">
          <FbFormInput
            className="w-1/2"
            type="number"
            label={'Total förväntning att fördela'}
            id={'1'}
            onBlur={(value: string | undefined) => setTotalFoerdelaFoervaentningInput(value?.replaceAll(' ', '') ?? '')}
          />
          <AriaCombobox
            className="w-[50%] mt-4"
            id={'foerdela'}
            label="Fördela enligt"
            values={[
              { id: 1, value: 'Utfall senaste 12 månaderna' },
              { id: 2, value: 'Utfall föregående år' },
            ]}
            defaultSelectedId={1}
            onItemSelected={v => {
              if (Number(v?.id)) {
                setSelectTargetFoerdelning(Number(v?.id));
              } else if (v?.id === undefined) {
                setSelectTargetFoerdelning(undefined);
              }
            }}
            shouldFlipOptions={false}
          />
          <div className="text-sm font-bold mb-2 mt-10">Fördelade förväntningar</div>
          <FbTableGrid
            className=" w-full"
            containerClassName="overflow-y-visible"
            columns={[
              {
                columnTemplate: 'max-content',
                header: [
                  {
                    type: 'value',
                    value: getUnderliggandeNamn(budget, true),
                    className: ' border-fb-color-grey-table-border border-l' + FbGridColumnHeaderStyles.table,
                    wrapperClassName: '' + FbGridColumnHeaderWrapperStyles.table,
                    zIndex: 0,
                  },
                ],
                footer: {
                  type: 'element',
                  className: ' bg-fb-color-white',
                  element: (
                    <div className={`font-semibold flex flex-col justify-between text-xs-increased pt-2 pl-2 `}>
                      <span>Summa fördelat</span>
                      <span>Diff mot egen budget</span>
                    </div>
                  ),
                },
              },
              {
                columnTemplate: 'max-content',
                header: [
                  {
                    type: 'value',
                    value: 'Beräknad förväntning',
                    className: ' border-fb-color-grey-table-border' + FbGridColumnHeaderStyles.table,
                    wrapperClassName: '' + FbGridColumnHeaderWrapperStyles.table,
                    zIndex: 0,
                  },
                ],
                footer: {
                  type: 'element',
                  className: ' bg-fb-color-white',
                  element: (
                    <div className={` font-semibold flex flex-col justify-between text-xs-increased text-right pt-2`}>
                      <span
                        className={`${
                          +totalFoerdelaFoervaentningInput !==
                          underliggandeUtfallFoerdelning.reduce((prev, next) => prev + (next.foerdelning ?? 0), 0)
                            ? 'text-fb-color-danger'
                            : ''
                        }`}
                      >
                        <div className="flex justify-end">
                          {+totalFoerdelaFoervaentningInput !== underliggandeUtfallSumma && (
                            <AriaTooltipAndTrigger
                              tooltips={[
                                {
                                  text: 'Observera att summa fördelat inte motsvarar utgångsvärde, då vi endast kan fördela ut heltal.',
                                  position: 'top',
                                  className: 'max-w-[290px]',
                                },
                              ]}
                            >
                              <div className="relative">
                                <FontAwesomeIcon icon={faCircleInfo} className="text-fb-color-primary mt-1 mx-1 peer" />
                              </div>
                            </AriaTooltipAndTrigger>
                          )}

                          {addSpacesToNumber(underliggandeUtfallSumma.toString())}
                        </div>
                      </span>

                      <span
                        className={`${
                          underliggandeUtfallSumma - (nyckeltalVaerde.Aarsvaerde ?? 0) < 0 ? 'text-fb-color-danger' : ''
                        }`}
                      >
                        {addSpacesToNumber(
                          (underliggandeUtfallSumma - (nyckeltalVaerde.Aarsvaerde ?? 0)).toString().replace('-', '- ')
                        )}
                      </span>
                    </div>
                  ),
                },
              },
            ]}
            rows={underliggandeEnheter.UnderliggandeEnheter.map(underliggande => {
              const value: FbTableGridRowDef = {
                values: [
                  {
                    value: `${underliggande.Namn}`,
                    className: ' border-l border-fb-color-grey-table-border',
                  },
                  {
                    value: `${
                      totalFoerdelaFoervaentningInput
                        ? addSpacesToNumber(
                            underliggandeUtfallFoerdelning
                              .find(u => u.budgetId === underliggande.BudgetPK.BudgetId)
                              ?.foerdelning?.toString() ?? ''
                          )
                        : ''
                    }`,
                    className: 'text-right',
                  },
                ],
              };

              return value;
            }).sort((rowA, rowB) => {
              var namnA = rowA.values[0].value?.toString().toUpperCase();
              var namnB = rowB.values[0].value?.toString().toUpperCase();

              if (namnA != null && namnB != null) {
                if (namnA < namnB) return -1;
                if (namnB > namnA) return 1;
                return 0;
              }
              if (namnA != null) return 1;
              if (namnB != null) return -1;
              return 0;
            })}
          />
        </div>
      </FbModal>
    </>
  );
};
